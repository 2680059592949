import * as React from "react";
import { graphql } from "gatsby";
import {
  Text
} from "@chakra-ui/react";

import {
  SectionContainer,
  SectionWrapper
} from "../components/Section";

import Layout from "../components/Layout";
import Head from "../components/Head";

import { CallToAction } from "../components/CallToAction";
import { Carousel } from "../components/Carousel";
import { Flexbox } from "../components/Flexbox";
import { Grid } from "../components/Grid";
import { ServiceHero } from "../components/Hero";
import { Ticker } from "../components/Ticker";


interface Props {
  data: any
}


const ServiceTemplate: React.FC<Props> = ( props ) => {
  const {
    mdx: {
      frontmatter: {
        title,
        seo_title,
        seo_description,
        heading,
        description,
        image,
        call_to_action_mobile,
        call_to_action_desktop,
      }
    },
    benefits,
    info,
    projects,
    concrete,
    laneway,
    sumppump,
    underpinning,
    waterproofing,
    testimonials
  } = props.data;

  return (
    <Layout>
      <Head
        title={ seo_title }
        description={ seo_description }
      />

      <SectionWrapper
        background="background.aliceblue"
        padding="153px 0 138px"
      >
        <SectionContainer>
          <ServiceHero
            heading={ heading }
            description={ description }
            image={ image.childImageSharp.gatsbyImageData }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="100px 0"
      >
        <SectionContainer>
          { title === "Concrete" &&
            <Grid
              nodes={ concrete.edges }
              template="project"
            />
          }
          { title === "Laneway & Garden Suite Foundations" &&
            <Grid
              nodes={ laneway.edges }
              template="project"
            />
          }
          { title === "Sump Pump" &&
            <Grid
              nodes={ sumppump.edges }
              template="project"
            />
          }
          { title === "Underpinning" &&
            <Grid
              nodes={ underpinning.edges }
              template="project"
            />
          }
          { title === "Waterproofing" &&
            <Grid
              nodes={ waterproofing.edges }
              template="project"
            />
          }
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper>
        <CallToAction
          mobileImage={ call_to_action_mobile.childImageSharp.gatsbyImageData }
          desktopImage={ call_to_action_desktop.childImageSharp.gatsbyImageData }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="100px 0 120px"
      >
        <SectionContainer>
          <Flexbox
            nodes={ info.edges }
            layout="info"
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="50px 0 84px"
      >
        <SectionContainer>
          <Ticker
            itemWidth={ 280 }
            template="benefit"
            nodes={ benefits.edges }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="background.aliceblue"
        padding="110px 0"
      >
        <Carousel
          nodes={ testimonials.edges }
          options={{
            loop: true
          }}
          template="testimonial"
          heading={
            <>
              People <Text as="span" color="primary.900">love</Text> what we do
            </>
          }
          headingAlignment="space-between"
        />
      </SectionWrapper>

      <SectionWrapper
        padding="110px 0"
      >
        <Carousel
          nodes={ projects.edges }
          options={{
            loop: true
          }}
          template="project"
          heading="Featured Projects"
          headingAlignment="space-between"
          headingWidth={{
            base: '100%',
            lg: '600px'
          }}
          description="We're proud of the work we've done to help transform the Greater Toronto Area. Here are a few of the projects that have made their mark on the city."
          slug="/portfolio"
          buttonText="Explore More Projects"
        />
      </SectionWrapper>
    </Layout>
  )
}


export const query = graphql`
  query Service($id: String) {
    mdx(
      id: {
        eq: $id
      }
    ) {
      frontmatter {
        title
        seo_title
        seo_description
        heading
        description
        image {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        call_to_action_mobile {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        call_to_action_desktop {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }

    benefits: allMdx(
      filter: {
        frontmatter: {
          template: {
            eq: "benefit"
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            heading
            icon
          }
        }
      }
    }

    info: allMdx(
      filter: {
        frontmatter: {
          template: {
            eq: "info"
          }
        }
      }
      sort: {
        fields: [frontmatter___order]
        order: ASC
      }
    ) {
      edges {
        node {
          frontmatter {
            heading
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            subheading
            description
          }
        }
      }
    }

    projects: allMdx(
      filter: {
        frontmatter: {
          template: {
            eq: "project"
          }
          featured: {
            eq: true
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            heading
            project_type
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }

    concrete: allMdx(
      filter: {
        frontmatter: {
          type: {
            eq: "concrete"
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            heading
            project_type
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }

    laneway: allMdx(
      filter: {
        frontmatter: {
          type: {
            eq: "laneway"
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            heading
            project_type
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }

    sumppump: allMdx(
      filter: {
        frontmatter: {
          type: {
            eq: "sump-pump"
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            heading
            project_type
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }

    underpinning: allMdx(
      filter: {
        frontmatter: {
          type: {
            eq: "underpinning"
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            heading
            project_type
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }

    waterproofing: allMdx(
      filter: {
        frontmatter: {
          type: {
            eq: "waterproofing"
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            heading
            project_type
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }

    testimonials: allMdx(
      filter: {
        frontmatter: {
          template: {
            eq: "testimonial"
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            author_name
            author_title
            testimonial
          }
        }
      }
    }
  }
`;


export default ServiceTemplate;
